<template>
  <div>
    <b-row class="title">
      <p class="p4">
        <img src="../../../../../assets/images/MDI-information-outline.svg" />
        Importante: São permitidas no máximo 02 áreas de atuação ativas por
        especialidade.
      </p>
    </b-row>
    <div v-show="!this.loading">
      <AccordionList
        titleKeyName="specialtyDescription"
        :items="this.specialties"
        :iconName="this.iconName"
        :getCustomTable="this.getCustomTable"
        class="custom-accordion-header"
      >
      </AccordionList>
    </div>
    <div v-show="this.loading">
      <b-skeleton-table
        :rows="3"
        :columns="2"
        :table-props="{ bordered: true, striped: true }"
      ></b-skeleton-table>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import AccordionList from "../../../../../components/accordion-list/AccordionList.vue";
import { getOccupationAreasNotification } from "../../../../../services/occupation-area/occupation-area-services";
import OccupationAreasTable from "../../visualization/occupationAreas/OccupationAreasTable.vue";
import { createToast } from "../../../../../components/toast/toast";

Vue.component("occupation-area-table", () =>
  import("./OccupationAreasTable.vue")
);

export default {
  components: {
    AccordionList,
  },
  mounted() {
    const crm = this.$store.getters.getUserName;

    this.getOccupationAreas(crm);
  },
  data() {
    return {
      specialties: [],
      medicalAreaOptions: [],
      loading: true,
      iconName: [
        { icon: "iconMedicalGuide", tooltip: "Divulgar Guia Médico" },
        { icon: "iconSpecialtyMedical", tooltip: "Especialidade Principal" },
      ],
    };
  },
  methods: {
    async getOccupationAreas(crm) {
      try {
        this.loading = true;
        getOccupationAreasNotification(crm).then((specialties) => {
          specialties.map((specialty) => {
            if (specialty.mainSpecialtyMedical.toLowerCase() == "s") {
              specialty.mainSpecialtyMedical = true;
              specialty.iconSpecialtyMedical = "heart-pulse.svg";
            } else {
              specialty.mainSpecialtyMedical = false;
              specialty.iconSpecialtyMedical = null;
            }

            specialty.occupations.map((occupation) => {
              occupation.idSpecialty = specialty.idSpecialty;
              occupation.aborted = false;
              if (occupation.publishMedicalGuide.toLowerCase() == "s") {
                occupation.publishMedicalGuide = true;
                occupation.iconMedicalGuide = "notebook-check-outline.svg";
              } else {
                occupation.publishMedicalGuide = false;
                occupation.iconMedicalGuide = null;
              }
            });
          });

          this.specialties = specialties;
          this.loading = false;
        });
      } catch (error) {
        this.loading = false;
        console.error("getOccupationAreas", error);
      }
    },
    onEditePublishMedicalGuideHandler(item) {
      try {
        item.aborted = false;

        const specialtie = this.specialties.filter(
          (x) => x.idSpecialty == item.idSpecialty
        );

        const publishMapArray = specialtie[0].occupations
          .map((e) => e.publishMedicalGuide)
          .filter((e) => e == true);

        if (publishMapArray.length > 2) {
          createToast(
            "Informação",
            "São permitidas no máximo 02 áreas de atuação ativas por especialidade.",
            "info"
          );
          item.publishMedicalGuide = false;
          item.aborted = true;
        }
      } catch (error) {
        console.error("onEditePublishMedicalGuideHandler", error);
      }
    },
    getCustomTable(item) {
      return (
        <OccupationAreasTable
          specialty={item}
          publishEvent={this.onEditePublishMedicalGuideHandler}
        />
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  padding-left: 1.5rem;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.card-header {
  cursor: pointer;
}
.custom-accordion-header .accordion-header {
  margin-left: 1rem;
}
</style>
