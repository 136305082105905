<template>
  <div>
    <div
      v-for="occupation in this.occupations"
      v-bind:key="occupation.idOccupationArea"
    >
      <b-row>
        <b-col class="padding-element-div">
          ÁREA DE ATUAÇÃO
        </b-col>
        <b-col class="padding-element-div text-right">
          {{ occupation.mainSpecialtyMedical ? "Sim" : "Não" }}
        </b-col>
      </b-row>
      <b-row>
        <b-col class="padding-element-div">
          DIVULGAR NO GUIA MÉDICO?
          <img src="@/assets/icons/notebook-check-outline-gray.svg" />
        </b-col>
        <b-col class="padding-element-div text-right">
          <b-form-checkbox
            switch
            v-model="occupation.publishMedicalGuide"
            @change="onEditHandler(occupation)"
          >
            {{ occupation.publishMedicalGuide ? "Sim" : "Não" }}
          </b-form-checkbox>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { updateOccupationAreaPublicizeOption } from "../../../../../services/occupation-area/occupation-area-services";
import TypeOcurrences from "../../../../../static-data/TypeOcurrences";
import {
  addOccurrenceStorage,
  createNewOcurrence,
} from "../../../../../utils/update-occurrences/occurrences-utils";
const tableFields = [
  {
    key: "occupationArea",
    label: "ÁREA DE ATUAÇÃO",
    tdClass: "text-right mb-3",
    thStyle: {
      display: "none",
    },
  },
  {
    key: "publishMedicalGuide",
    label: "DIVULGAR NO GUIA MÉDICO?",
    tdClass: "text-right",
    thStyle: {
      display: "none",
    },
  },
];

export default {
  props: ["specialty", "publishEvent"],
  mounted() {
    this.specialty = this.$props.specialty;
    this.occupations = this.specialty ? this.$props.specialty?.occupations : [];
  },
  data() {
    return {
      occupations: [],
      tableFields: tableFields,
    };
  },
  methods: {
    onEditHandler(item) {
      if (this.$store.getters.isAdmin) {
        return;
      }
      if (this.$props.publishEvent) this.$props.publishEvent(item);

      if (item.aborted == false) {
        var request = {
          crm: this.$store.getters.getUserName,
          idSpecialty: this.specialty.idSpecialty,
          publicize: item.publishMedicalGuide ? "S" : "N",
          occupationArea: item.idOccupationArea,
        };

        updateOccupationAreaPublicizeOption(request);

        const newOcurrence = createNewOcurrence(
          TypeOcurrences.OCUCPATION_AREA,
          this.specialty.idSpecialty + "_" + item.idOccupationArea,
          item.publishMedicalGuide,
          `Alteração da area de atuaçao ${item.occupationArea} da especilidade ${this.specialty.specialtyDescription}`,
          item.publishMedicalGuide ? false : true
        );
        addOccurrenceStorage(newOcurrence);
      }
    },
  },
};
</script>

<style lang="scss">
.occupation.table td {
  border: none;
  padding: 0;
}
.occupation.table tr {
  padding: 1.5rem 3rem;
  border-top: 1px solid var(--light-0);
}

.occupation.table.b-table.b-table-stacked {
  & :first-child {
    border: none;
    border-color: transparent;
  }
}

.custom-control-label,
.custom-control-label {
  margin-top: 4px !important;
  padding-top: px;
}
</style>
